import React from 'react';
import { Box } from '@mui/material';

const UpcomingProjects = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        top: { xs: '40px', md: '70px' },
        width: '100%',
        height: { xs: 'auto', md: '3227px' },
        bgcolor: '#F1F1F1',
        color: '#313132',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          position: { xs: 'static', md: 'absolute' },
          top: '41px',
          width: '440px',
          height: '49px',
          fontSize: '40px',
          fontFamily: 'Montserrat, SemiBold',
          textAlign: 'center',
          letterSpacing: '0',
          lineHeight: '49px',
          textTransform: 'uppercase',
          whiteSpace: 'nowrap',
        }}
      >
        Upcoming Projects
      </Box>
    </Box>
  );
};

export default UpcomingProjects;

import React from 'react';
import image from '../assets/Mask Group 5.png';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const RectangleWithTextAndImage = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        mt: { xs: '20px', sm: '40px', md: '-550px' },
        width: '100%',
        height: { xs: '300px', sm: '400px', md: '680px' },
        background: '#F5F5F5',
        opacity: 1,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: '100%',
          height: '100%',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
      >
        <img
          src={image}
          alt=""
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            width: { xs: '80%', sm: '40%', md: '550px' },
            textAlign: 'center',
            zIndex: '2',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              position: 'absolute',
              top: { xs: '15%', sm: '25%', md: '-300px' },
              left: { xs: '5%', sm: '20%', md: '50px' },
              fontSize: { xs: '4vw', sm: '3vw', md: '45px' },
              fontFamily: 'Montserrat, Medium',
              lineHeight: 1.2,
              color: '#313132',
              textTransform: 'uppercase',
            }}
          >
            Assistant Global
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              position: 'absolute',
              top: { xs: '25%', sm: '35%', md: '-245px' },
              left: { xs: '5%', sm: '20%', md: '-5px' },
              width: { xs: '80%', sm: '40%', md: '562px' },
              fontSize: { xs: '3vw', sm: '2vw', md: '16px' },
              fontFamily: 'Montserrat, Regular',
              letterSpacing: '15px',
              lineHeight: 1.2,
              color: '#313132',
              textTransform: 'uppercase',
            }}
          >
            For The People
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default RectangleWithTextAndImage;

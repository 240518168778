import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/system';
import logo from '../assets/Path 314.png';

const Header = () => {
  return (
    <AppBar position="relative" sx={{ backgroundColor: '#313132', width: '100%' }}>
      <Toolbar sx={{ 
        justifyContent: 'center', 
        paddingLeft: { xs: '2%', sm: '5%', md: '13%' }, 
        paddingRight: { xs: '2%', sm: '5%', md: '30%' },
      }}>
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: 'row', 
            alignItems: 'center',
            gap: { xs: '2%', sm: '4%', md: '10%' },
          }}
        >
          <img src={logo} alt="logo" sx={{ width: '14px', height: '13px', flexShrink: 0, backgroundColor: '#FFFFFF', opacity: 1, color: '#FFFFFF' }}/>
          <Button sx={{ color: '#F3F3F3', fontFamily: 'Montserrat', fontSize: '12px', textTransform: 'none', opacity: 1 }}>Shop</Button>
          <Button sx={{ color: '#F3F3F3', fontFamily: 'Montserrat', fontSize: '12px', textTransform: 'none', opacity: 1 }}>Products</Button>
          <Button sx={{ color: '#F3F3F3', fontFamily: 'Montserrat', fontSize: '12px', textTransform: 'none', opacity: 1 }}>Services</Button>
          <Button sx={{ color: '#F3F3F3', fontFamily: 'Montserrat', fontSize: '12px', textTransform: 'none', opacity: 1 }}>Contact</Button>
          <Button sx={{ color: '#F3F3F3', fontFamily: 'Montserrat', fontSize: '12px', textTransform: 'none', opacity: 1 }}>Support</Button>
          <IconButton sx={{ color: '#F3F3F3', width: '15px', height: '15px', opacity: 1 }}>
            <SearchIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;

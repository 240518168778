import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

// Import the images
import image from '../assets/drive-image.png';
import image1 from '../assets/raw.png';

function CardElement() {
  return (
    <Container maxWidth="lg">
      <Grid container justify="center" alignItems="center" style={{ position : 'relative', top: '-3000px', width: '100%', }}>
        <Grid item xs={12} md={6} style={{ paddingLeft: '10px', paddingRight:'10px' }}>
          <Box 
            component="div" 
            sx={{
              width: '100%',
              height: '620px',
              borderRadius: '0px 0px 10px 10px',
              opacity: '1',
              backgroundColor: '#757575',
              boxSizing:'border-box',
              float: 'left',
              textAlign: 'center',
              position: 'relative', // added
            }}
          >
            <img src={image} alt="Drive" style={{ position: 'absolute', top: '200px', width: '600px', height: '400px', left:'-40px' }} />  
            <p style={{fontSize: '65px', fontFamily: 'Montserrat, Regular', textTransform: 'uppercase', color:'#E2E2E2', marginTop: '20px'}}>Drive</p> 
            <p style={{fontSize: '16px', fontFamily: 'Neutra Text, Book', textTransform: 'uppercase', color:'#E2E2E2', whiteSpace: 'nowrap', marginTop: '-70px'}}>Secured Drive</p>
            <button style={{fontSize: '20px', fontFamily: 'Montserrat, Regular', color: '#3BAAFF', border: 'none', backgroundColor: 'transparent', cursor: 'pointer', marginTop:'-10px'}}>Explore&#62;</button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} style={{ paddingLeft: '10px', paddingRight:'10px' }}>
          <Box 
            component="div" 
            sx={{
              width: '100%',
              height: '620px',
              borderRadius: '0px 0px 10px 10px',
              opacity: '1',
              backgroundColor: '#757575',
              boxSizing:'border-box',
              float: 'Right',
              textAlign: 'center',
              position: 'relative', // added
            }}
          >
            <img src={image1} alt="aPay" style={{ position: 'absolute', top: '220px', width: '700px', height: '400px', left:'-100px' }} /> 
            <p style={{fontSize: '70px', fontFamily: 'Segoe UI, Bold', color:'#E2E2E2', marginTop: '10px',marginLeft:'13px'}}>aPay</p> 
            <p style={{fontSize: '12px', fontFamily: 'Montserrat, Regular', textTransform: 'uppercase', color:'#E2E2E2', whiteSpace: 'nowrap', marginTop: '-83px'}}>It's Secured</p>
            <button style={{fontSize: '20px', fontFamily: 'Montserrat, Regular', color: '#3BAAFF', border: 'none', backgroundColor: 'transparent', cursor: 'pointer'}}>Explore&#62;</button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default CardElement;

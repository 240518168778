import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

// Import the images
import image from '../assets/bulb.png';
import image1 from '../assets/house.png';

function CardElement2() {
  return (
    <Container maxWidth="lg">
      <Grid container justify="center" alignItems="center" style={{ position : 'relative', top: '-2990px', width: '100%', }}>
        <Grid item xs={12} md={6} style={{ paddingLeft: '10px', paddingRight:'10px' }}>
          <Box 
            component="div" 
            sx={{
              width: '100%',
              height: '720px',
              borderRadius: '0px 0px 10px 10px',
              opacity: '1',
              backgroundColor: '#F5F5F5',
              boxSizing:'border-box',
              position: 'relative',
              textAlign: 'center',
            }}
          >
           <img src={image} alt="bulb" style={{ position: 'absolute', top: '150px', width: '550px', height: '500px', left:'0px' }} />  
           <p style={{fontSize: '65px', fontFamily: 'Neutra Text, Book', textTransform: 'uppercase', color:'#313132', marginTop: '20px'}}>Abs</p> 
           <p style={{fontSize: '16px', fontFamily: 'Neutra Text, Book', textTransform: 'uppercase', color:'#313132', whiteSpace: 'nowrap', marginTop: '-70px'}}>Your Business Solution</p>
            <button style={{fontSize: '20px', fontFamily: 'Montserrat, Regular', color: '#3BAAFF', border: 'none', backgroundColor: 'transparent', cursor: 'pointer', marginTop:'-10px'}}>Explore&#62;</button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} style={{ paddingLeft: '10px', paddingRight:'10px' }}>
          <Box 
            component="div" 
            sx={{
              width: '100%',
              height: '720px',
              borderRadius: '0px 0px 10px 10px',
              opacity: '1',
              backgroundColor: '#F5F5F5',
              boxSizing:'border-box',
              position: 'relative',
              textAlign: 'center'
            }}
          >
            <img src={image1} alt="house" style={{ position: 'absolute', top: '150px', width: '550px', height: '500px', left:'0px' }} /> 
            <p style={{fontSize: '55px', fontFamily: 'Montserrat, Regular', textTransform: 'uppercase', color:'#313132', marginTop: '10px',marginLeft:'13px'}}>Property</p> 
            <p style={{fontSize: '16px', fontFamily: 'Neutra Text, Book', textTransform: 'uppercase', color:'#313132', whiteSpace: 'nowrap', marginTop: '-53px'}}>Make secure your future</p>
            <button style={{fontSize: '20px', fontFamily: 'Montserrat, Regular', color: '#3BAAFF', border: 'none', backgroundColor: 'transparent', cursor: 'pointer'}}>Explore&#62;</button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default CardElement2;

import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const ShowMoreButton = () => {
  return (
    <Box
      sx={{
        position: 'relative', 
        top: { xs: '50px', sm: '100px', md: '40px' }, 
        display: 'flex', 
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Button
        variant="contained"
        sx={{
          width: { xs: '70%', sm: '50%', md: '255px' },
          height: { xs: '40px', sm: '40px', md: '54px' },
          fontSize: { xs: '14px', sm: '16px', md: '20px' },
          fontFamily: 'Montserrat, Regular',
          backgroundColor: '#313132',
          borderRadius: '4px',
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#313132',
          },
        }}
      >
        Show more
      </Button>
    </Box>
  );
};

export default ShowMoreButton;

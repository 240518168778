import React from 'react';
import imagePath from '../assets/Group 421.png';
import Box from '@mui/material/Box';

const ImageWithText = () => {
  return (
    <Box
  sx={{
    position: 'relative',
    mt: '30px', // consistent margin-top across all screen sizes
    left: '50%',
    transform: 'translateX(-50%)',
    width: '90%',
    maxWidth: '600px',
    textAlign: 'center',
    aspectRatio: '1 / 1',
  }}
>
      <img
        src={imagePath}
        alt=""
        style={{
          maxWidth: '100%',
          height: 'auto',
        }}
      />
    </Box>
  );
};

export default ImageWithText;

import React from 'react';
import image from '../assets/Group 475.png';
import Box from '@mui/material/Box';

const ProjectImage = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        mt: { xs: '10px', sm: '20px', md: '74px' },
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: { xs: 'calc(100% - 80px)', sm: '100%', md: '100%' },
          px: { xs: '40px', sm: '0px', md: '40px' },
        }}
      >
        <img
          src={image}
          alt=""
          style={{
            maxWidth: '100%',
            height: 'auto',
            objectFit: 'contain',
          }}
        />
      </Box>
    </Box>
  );
};

export default ProjectImage;

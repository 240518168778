import React from 'react';
import Header from './components/Header'; // Adjust this path as needed to match your project's structure
import ImageWithText from './components/ImageWithText';
import ProjectImage from './components/ProjectImage';
import RectangleWithTextAndImage from './components/RectangleWIthTextAndImage';
import RunningProjectText from './components/RunningProjectText';
import ShowMoreButton from './components/ShowMoreButton';
import UpcomingProjects from './components/UpcomingProjects';
import CardElement from './components/CardElement';
import CardElement2 from './components/CardElement2';

function App() {
  return (
    <div className="App">
      <Header />
      <ImageWithText/>
      <RectangleWithTextAndImage/>
      <RunningProjectText/>
      <ProjectImage/>
      <ShowMoreButton/>
      <UpcomingProjects/>
      <CardElement/>
      <CardElement2/>
    
     
      {/* Other components would go here */}
    </div>
  );
}

export default App;

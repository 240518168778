import React from 'react';

const RunningProjectText = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '841px',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <p
        style={{
          width: '404px',
          height: '49px',
          fontSize: '40px',
          font: 'Montserrat, SemiBold',
          textAlign: 'center',
          letterSpacing: '0',
          lineHeight: '49px',
          margin: '0',
          color: '#313132',
          textTransform: 'uppercase',
        }}
      >
        Running Projects
      </p>
    </div>
  );
};

export default RunningProjectText;
